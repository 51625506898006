<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>This is an about page</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>Line Chart</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <line-chart></line-chart>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>Bar Chart</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <bar-chart></bar-chart>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>bubble Chart</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <bubble-chart></bubble-chart>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>Reactivity - Live update upon change in datasets</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <reactive :chart-data="datacollection"></reactive>
        <button class="button is-primary" @click="fillData()">Randomize</button>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LineChart from "@/components/ChartTest";
import BarChart from "@/components/BarChart";
import BubbleChart from "@/components/BubbleChart";
import Reactive from "@/components/Reactive";

export default {
  name: "VueChartJS",
  components: {
    LineChart,
    BarChart,
    BubbleChart,
    Reactive,
  },
  data() {
    return {
      datacollection: null, // instantiating datacollection with null
    };
  },
  created() {
    this.fillData(); //anytime the vue instance is created, call the fillData() function.
  },
  methods: {
    fillData() {
      this.datacollection = {
        // Data for the y-axis of the chart
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: [
          {
            label: "Data One",
            backgroundColor: "#f87979",
            // Data for the x-axis of the chart
            data: [
              this.getRandomInt1(),
              this.getRandomInt1(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
              this.getRandomInt(),
            ],
          },
        ],
      };
    },
    getRandomInt() {
      // JS function to generate numbers to be used for the chart
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
    getRandomInt1() {
      // JS function to generate numbers to be used for the chart
      return 50;
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
